<template>
	<div class="px-0 px-sm-5 px-xl-10">
		<div class="d-flex flex-row justify-content-around align-items-center card header-nav px-xl-20">
			<div class="pointer d-flex flex-column align-items-center" :class="{ active: active === 'details' }" @click="scrollTo('details')">
				<div class="wizard-icon">
					<span class="svg-icon svg-icon-2x">
						<inline-svg src="/media/svg/icons/Code/Info-circle.svg" />
					</span>
				</div>
				<div>
					Details
				</div>
			</div>
			<div class="pointer d-flex flex-column align-items-center" :class="{ active: active === 'remote' }" @click="scrollTo('remote')">
				<div class="wizard-icon">
					<span class="svg-icon svg-icon-2x">
						<inline-svg src="/media/svg/icons/Map/Compass.svg" />
					</span>
				</div>
				<div>
					Remote
				</div>
			</div>
			<div class="pointer d-flex flex-column align-items-center" :class="{ active: active === 'voice' }" @click="scrollTo('voice')">
				<div class="wizard-icon">
					<span class="svg-icon svg-icon-2x">
						<inline-svg src="/media/svg/icons/Communication/Call.svg" />
					</span>
				</div>
				<div>
					Voice
				</div>
			</div>
			<div class="pointer d-flex flex-column align-items-center" :class="{ active: active === 'ip' }" @click="scrollTo('ip')">
				<div class="wizard-icon">
					<span class="svg-icon svg-icon-2x">
						<inline-svg src="/media/svg/icons/Devices/Router1.svg" />
					</span>
				</div>
				<div>
					IP
				</div>
			</div>
			<div class="pointer d-flex flex-column align-items-center" :class="{ active: active === 'rf' }" @click="scrollTo('rf')">
				<div class="wizard-icon">
					<span class="svg-icon svg-icon-2x">
						<inline-svg src="/media/svg/icons/Devices/LTE1.svg" />
					</span>
				</div>
				<div>
					RF
				</div>
			</div>
			<div class="pointer d-flex flex-column align-items-center" :class="{ active: active === 'comments' }" @click="scrollTo('comments')">
				<div class="wizard-icon">
					<span class="svg-icon svg-icon-2x">
						<inline-svg src="/media/svg/icons/Communication/Chat1.svg" />
					</span>
				</div>
				<div>
					Comments
				</div>
			</div>
		</div>
		<div class="card card-custom card-stretch">
			<div class="wizard wizard-2 wizard-container">
				<!-- <div class="wizard-nav border-right py-10 px-8 py-lg-20 px-lg-10">
				<div class="row pl-7 mb-5">
					<h1 style="font-weight: 300;"><span v-if="!edit">New</span><span v-else>Edit</span> Service Request</h1>
				</div>
				<div class="wizard-steps">
					<div class="wizard-step pointer" :class="{ active: active === 'details' }" @click="scrollTo('details')">
						<div class="wizard-wrapper">
							<div class="wizard-icon">
								<span class="svg-icon svg-icon-2x">
									<inline-svg src="/media/svg/icons/Code/Info-circle.svg" />
								</span>
							</div>
							<div class="wizard-label">
								<h3 class="wizard-title">
									Request Details
								</h3>
								<div class="wizard-desc">
									Required Information
								</div>
							</div>
						</div>
					</div>
					<div class="wizard-step pointer" :class="{ active: active === 'remote' }" @click="scrollTo('remote')">
						<div class="wizard-wrapper">
							<div class="wizard-icon">
								<span class="svg-icon svg-icon-2x">
									<inline-svg src="/media/svg/icons/Map/Compass.svg" />
								</span>
							</div>
							<div class="wizard-label">
								<h3 class="wizard-title">
									Remote Requirements
								</h3>
								<div class="wizard-desc">
									Site Information
								</div>
							</div>
						</div>
					</div>
					<div class="wizard-step pointer" :class="{ active: active === 'voice' }" @click="scrollTo('voice')">
						<div class="wizard-wrapper">
							<div class="wizard-icon">
								<span class="svg-icon svg-icon-2x">
									<inline-svg src="/media/svg/icons/Communication/Call.svg" />
								</span>
							</div>
							<div class="wizard-label">
								<h3 class="wizard-title">
									Voice Requirements
								</h3>
								<div class="wizard-desc">
									DID Requirements
								</div>
							</div>
						</div>
					</div>
					<div class="wizard-step pointer" :class="{ active: active === 'ip' }" @click="scrollTo('ip')">
						<div class="wizard-wrapper">
							<div class="wizard-icon">
								<span class="svg-icon svg-icon-2x">
									<inline-svg src="/media/svg/icons/Devices/Router1.svg" />
								</span>
							</div>
							<div class="wizard-label">
								<h3 class="wizard-title">
									IP Requirements
								</h3>
								<div class="wizard-desc">
									MIR/CIR and IPs
								</div>
							</div>
						</div>
					</div>
					<div class="wizard-step pointer" :class="{ active: active === 'rf' }" @click="scrollTo('rf')">
						<div class="wizard-wrapper">
							<div class="wizard-icon">
								<span class="svg-icon svg-icon-2x">
									<inline-svg src="/media/svg/icons/Devices/LTE1.svg" />
								</span>
							</div>
							<div class="wizard-label">
								<h3 class="wizard-title">
									RF Requirements
								</h3>
								<div class="wizard-desc">
									Antenna, BUC and LNB
								</div>
							</div>
						</div>
					</div>
					<div class="wizard-step pointer" :class="{ active: active === 'comments' }" @click="scrollTo('comments')">
						<div class="wizard-wrapper">
							<div class="wizard-icon">
								<span class="svg-icon svg-icon-2x">
									<inline-svg src="/media/svg/icons/Communication/Chat1.svg" />
								</span>
							</div>
							<div class="wizard-label">
								<h3 class="wizard-title">
									Notifications and Comments
								</h3>
								<div class="wizard-desc">
									Any additional info
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> -->
				<!--end: Wizard Nav -->

				<div class="wizard-body form-body py-0 py-lg-5 px-0 px-xl-20" :class="{ loaded: loaded }">
					<ITCSpinner :loaded="loaded">
						<div class="row">
							<div class="col-12 offset-lg-1 col-lg-10 ">
								<b-form id="srf" @submit.prevent="submit">
									<!--REQUEST DETAILS-->
									<div class="px-10 py-7 p-md-7 section" ref="details">
										<h1 class="mb-5 font-weight-bold header-blue" v-b-visible="detailsVisible">
											Request Details
										</h1>
										<hr class="mb-10" />
										<div class="row">
											<div class="col-12 col-sm-6">
												<b-form-group label="Request Type" label-class="required-field">
													<b-form-radio-group v-model="data.request_type" name="request_type" :state="validate('request_type')" size="lg" stacked>
														<b-form-radio value="New Service">New Service</b-form-radio>
														<b-form-radio value="Add On Service">Add On Service</b-form-radio>
														<b-form-radio value="Change to Service">Change to Service</b-form-radio>
														<b-form-radio value="Capacity/Network Change">Capacity/Network Change</b-form-radio>
														<b-form-radio value="Disconnect">Disconnect</b-form-radio>
													</b-form-radio-group>
												</b-form-group>
											</div>
											<div class="col-12 col-sm-6">
												<b-form-group label="Billing Type:" label-for="billing_type" label-class="required-field">
													<b-form-select v-model="data.billing_type" @change="setBillingFlag(data.billing_type)" :state="validate('billing_type')">
														<b-form-select-option :value="null">Required</b-form-select-option>
														<b-form-select-option value="MRC Start">MRC Start</b-form-select-option>
														<b-form-select-option value="MRC Stop">MRC Stop</b-form-select-option>
														<b-form-select-option value="Change with MRC Increase">
															Change with MRC Increase
														</b-form-select-option>
														<b-form-select-option value="Change with MRC Decrease">
															Change with MRC Decrease
														</b-form-select-option>
														<b-form-select-option value="Change captured under current MRC">
															Change captured under current MRC
														</b-form-select-option>
														<b-form-select-option value="No Cost Change">
															No Cost Change
														</b-form-select-option>
													</b-form-select>
												</b-form-group>
												<b-form-group label="Quote Number/MSA" label-for="itc_quote_number" label-class="required-field">
													<b-form-input
														type="text"
														id="itc_quote_number"
														placeholder="Required"
														v-model="data.itc_quote_number"
														:state="validate('itc_quote_number')"
													/>
												</b-form-group>
												<b-form-group label="Request Description" label-for="request_description">
													<b-form-input
														type="text"
														id="request_description"
														placeholder="Brief description of request"
														v-model="data.request_description"
													/>
												</b-form-group>
											</div>
										</div>
										<div class="row">
											<div class="col-12 col-sm-6">
												<b-form-group label="Customer" label-for="customer" label-class="required-field">
													<b-form-input
														type="text"
														id="customer"
														placeholder="Required"
														v-model="data.customer_name"
														:state="validate('customer_name')"
													/>
												</b-form-group>
											</div>
											<div class="col-12 col-sm-6">
												<b-form-group label="Site Name" label-for="site_name" label-class="required-field">
													<VueTypeaheadBootstrap
														:data="modems"
														v-model="data.site_name"
														:serializer="s => s.name"
														placeholder="Required"
														@hit="data.modem_DID = +$event.DID"
														:class="{ 'typeahead-invalid': validate('site_name') === false }"
													/>
												</b-form-group>
											</div>
										</div>
										<div class="row">
											<div class="col-12 col-sm-6">
												<b-form-group label="Start Date" label-for="start_date" label-class="required-field">
													<b-form-datepicker
														type="text"
														id="start_date"
														placeholder="Required"
														v-model="data.service_start_date"
														:state="validate('service_start_date')"
													/>
												</b-form-group>
											</div>
											<div class="col-12 col-sm-6">
												<b-form-group label="End Date" label-for="end_date">
													<b-form-datepicker type="text" id="end_date" v-model="data.service_end_date" />
												</b-form-group>
											</div>
										</div>
										<div class="row">
											<div class="col-12 col-sm-6">
												<b-form-group label="Country of Operation" label-for="country_of_operation">
													<b-form-input type="text" id="country_of_operation" v-model="data.country_of_operation" />
												</b-form-group>
											</div>
											<div class="col-12 col-sm-6">
												<b-form-group label="Vessel IMO Number" label-for="vessel_imo_number">
													<b-form-input type="text" id="vessel_imo_number" v-model="data.vessel_imo_number" />
												</b-form-group>
											</div>
										</div>
										<div class="row">
											<div class="col-12 col-sm-6">
												<b-form-group label="Target Completion Date" label-for="target_completion_date">
													<b-form-datepicker type="text" id="target_completion_date" v-model="data.target_completion_date" />
												</b-form-group>
											</div>
										</div>
										<div class="row mt-5">
											<div class="col-12 text-center">
												<h2>Technical Point of Contact</h2>
											</div>
											<div class="col-12 col-sm-6 col-lg-3">
												<b-form-group label="Name" label-for="technical_poc_name">
													<b-form-input type="text" id="technical_poc_name" v-model="data.technical_poc_name" />
												</b-form-group>
											</div>
											<div class="col-12 col-sm-6 col-lg-3">
												<b-form-group label="Phone" label-for="technical_poc_phone">
													<b-form-input type="text" id="technical_poc_phone" v-model="data.technical_poc_phone" />
												</b-form-group>
											</div>
											<div class="col-12 col-sm-6 col-lg-3">
												<b-form-group label="Cell" label-for="technical_poc_cell">
													<b-form-input type="text" id="technical_poc_cell" v-model="data.technical_poc_cell" />
												</b-form-group>
											</div>
											<div class="col-12 col-sm-6 col-lg-3">
												<b-form-group label="Email" label-for="technical_poc_email">
													<b-form-input type="text" id="technical_poc_email" v-model="data.technical_poc_email" />
												</b-form-group>
											</div>
										</div>
										<div class="row">
											<div class="col-12 text-center">
												<h2>Site Point of Contact</h2>
											</div>
											<div class="col-12 col-sm-6 col-lg-3">
												<b-form-group label="Name" label-for="site_poc_name">
													<b-form-input type="text" id="site_poc_name" v-model="data.site_poc_name" />
												</b-form-group>
											</div>
											<div class="col-12 col-sm-6 col-lg-3">
												<b-form-group label="Phone" label-for="site_poc_phone">
													<b-form-input type="text" id="site_poc_phone" v-model="data.site_poc_phone" />
												</b-form-group>
											</div>
											<div class="col-12 col-sm-6 col-lg-3">
												<b-form-group label="Cell" label-for="site_poc_cell">
													<b-form-input type="text" id="site_poc_cell" v-model="data.site_poc_cell" />
												</b-form-group>
											</div>
											<div class="col-12 col-sm-6 col-lg-3">
												<b-form-group label="Email" label-for="site_poc_email">
													<b-form-input type="text" id="site_poc_email" v-model="data.site_poc_email" />
												</b-form-group>
											</div>
										</div>
									</div>
									<!--end: Wizard Step 1-->

									<!--begin: Remote - Step 2-->
									<div class="p-7 my-5 section" ref="remote">
										<h1 class="mb-5 font-weight-bold header-blue" v-b-visible="remoteVisible">
											Remote Requirements
										</h1>
										<hr class="mb-10" />
										<div class="row">
											<div class="col-12 col-md-6">
												<b-form-checkbox v-model="data.beam_switch" name="checkbox-1" value="Yes" unchecked-value="No" size="lg" switch>
													Beam Switch
												</b-form-checkbox>
											</div>
											<div class="col-12 col-md-6">
												<b-form-group label="Site Location">
													<b-form-radio-group v-model="data.location_type" name="location_type" size="lg">
														<b-form-radio value="Mobile">Mobile</b-form-radio>
														<b-form-radio value="Fixed">Fixed</b-form-radio>
													</b-form-radio-group>
												</b-form-group>
											</div>
										</div>
										<div class="row mb-4" v-if="data.location_type === 'Mobile'">
											<div class="col-12">
												<b-form-checkbox v-model="data.handshake_signaling" value="Yes" unchecked-value="No" size="lg" switch>
													Handshake Signaling
												</b-form-checkbox>
												<b-form-checkbox v-model="data.serial_gps" value="Yes" unchecked-value="No" size="lg" switch> Serial GPS</b-form-checkbox>
												<b-form-checkbox v-model="data.open_amip" value="Yes" unchecked-value="No" size="lg" switch> OpenAMIP</b-form-checkbox>
											</div>
										</div>
										<div class="row" v-else>
											<div class="col-12 col-sm-6">
												<b-form-group label="Latitude" label-for="location_lat">
													<b-form-input type="text" id="location_lat" v-model="data.location_lat" />
												</b-form-group>
											</div>
											<div class="col-12 col-sm-6">
												<b-form-group label="Longitude" label-for="location_lon">
													<b-form-input type="text" id="location_lon" v-model="data.location_lon" />
												</b-form-group>
											</div>
										</div>
										<div class="row">
											<div class="col-12 col-sm-6">
												<b-form-group label="Site Type">
													<b-form-radio-group v-model="data.site_type" name="site_type" size="lg">
														<b-form-radio value="Vessel">Vessel</b-form-radio>
														<b-form-radio value="Terrestrial">Terrestrial</b-form-radio>
													</b-form-radio-group>
												</b-form-group>
											</div>
											<div class="col-12 col-sm-6">
												<b-form-group label="Link Use">
													<b-form-radio-group v-model="data.link_use" name="link_use" size="lg">
														<b-form-radio value="Primary">Primary</b-form-radio>
														<b-form-radio value="Backup">Backup</b-form-radio>
													</b-form-radio-group>
												</b-form-group>
												<b-form-checkbox
													v-if="data.site_type === 'Vessel'"
													v-model="data.vessel_4g_redundancy"
													value="Yes"
													unchecked-value="No"
													switch
													size="lg"
													class="mb-4"
												>
													4G Redundancy
												</b-form-checkbox>
											</div>
										</div>
										<div class="row">
											<div class="col-12 col-sm-5">
												<b-form-group label="Requested Satellite">
													<b-form-select v-model="input.satellite" :options="satList" @change="addSatellite(input.satellite)"></b-form-select>
												</b-form-group>
											</div>
											<div class="col-12 col-sm-1" style="font-weight: 500;">OR</div>
											<div class="col-12 col-sm-6">
												<b-form-group label="Requested Region (Maritime Only)">
													<b-form-select v-model="input.region" :options="regionsList" @change="addRegionSats"></b-form-select>
												</b-form-group>
											</div>
											<div class="col-12 d-flex flex-wrap" v-if="data.requested_satellites">
												<div v-for="sat in data.requested_satellites" :key="sat.name" class="select-item my-1" @click="removeSat(sat.name)">
													{{ sat.name }}
												</div>
											</div>
										</div>
									</div>
									<!-- End Remote -->

									<!-- Begin Voice - Step 3-->
									<div class="p-7 my-5 section" ref="voice">
										<h1 class="mb-5 font-weight-bold header-blue" v-b-visible="voiceVisible">
											Voice Requirements
										</h1>
										<hr class="mb-10" />
										<div class="row">
											<div class="col-12 col-sm-5">
												<b-form-group label="Preferred DID Country" label-for="did_country">
													<b-form-input type="text" id="did_country" v-model="input.did_country" />
												</b-form-group>
											</div>
											<div class="col-12 col-sm-5">
												<b-form-group label="Required # of DIDs" label-for="no_of_dids">
													<b-form-input type="number" id="no_of_dids" v-model="input.no_of_dids" />
												</b-form-group>
											</div>
											<div class="col-12 col-sm-2 mt-2">
												<b-button
													variant="outline-primary"
													class="mt-md-2 mt-lg-8 w-100"
													@click="
														input.did_country &&
															addTo('countries_with_voice', ['did_country', 'no_of_dids'], {
																name: input.did_country,
																dids_required: input.no_of_dids,
															})
													"
												>
													Add Country
												</b-button>
											</div>
										</div>
										<div class="row mb-5" v-if="data.countries_with_voice">
											<div class="col-12 d-flex flex-wrap">
												<div
													v-for="(country, i) in data.countries_with_voice"
													:key="country.name"
													class="select-item my-1"
													@click="removeFrom('countries_with_voice', i)"
												>
													{{ country.name }} - {{ country.dids_required }} DIDs
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-12 mb-15">
												<b-form-checkbox v-model="data.customer_telco_devices" value="Yes" unchecked-value="No" size="lg" switch>
													Customer Telco Devices
												</b-form-checkbox>
											</div>
											<div class="col-12 col-sm-5" v-if="data.customer_telco_devices === 'Yes'">
												<b-form-group label="Device Type" label-for="customer_telco_device_type">
													<b-form-input type="text" id="customer_telco_device_type" v-model="input.telco_device_type" />
												</b-form-group>
											</div>
											<div class="col-12 col-sm-2 mt-sm-2" v-if="data.customer_telco_devices === 'Yes'">
												<b-button
													variant="outline-primary"
													class="mt-sm-8 w-100"
													@click="
														input.telco_device_type && addTo('customer_telco_device_types', 'telco_device_type', { type: input.telco_device_type })
													"
												>
													Add Device
												</b-button>
											</div>
											<div class="col-12 d-flex flex-wrap" v-if="data.customer_telco_device_types">
												<div
													v-for="(device, i) in data.customer_telco_device_types"
													:key="device.type"
													class="select-item my-1"
													@click="removeFrom('customer_telco_device_types', i)"
												>
													{{ device.type }}
												</div>
											</div>
										</div>
									</div>

									<!-- IP -->
									<div class="p-7 my-5 section" ref="ip">
										<h1 class="mb-5 font-weight-bold header-blue" v-b-visible="ipVisible">
											IP Requirements
										</h1>
										<hr class="mb-10" />
										<div class="row">
											<div class="col-2"><h2>MIR</h2></div>
											<div class="col-5">
												<b-form-group label="Down (kbps)" label-for="mir_down">
													<b-form-input type="text" id="mir_down" v-model="data.mir_down" />
												</b-form-group>
											</div>
											<div class="col-5">
												<b-form-group label="Up (kbps)" label-for="mir_up">
													<b-form-input type="text" id="mir_up" v-model="data.mir_up" />
												</b-form-group>
											</div>
										</div>
										<div class="row">
											<div class="col-2"><h2>CIR</h2></div>
											<div class="col-5">
												<b-form-group label="Down (kbps)" label-for="cir_down">
													<b-form-input type="text" id="cir_down" v-model="data.cir_down" />
												</b-form-group>
											</div>
											<div class="col-5">
												<b-form-group label="Up (kbps)" label-for="cir_up">
													<b-form-input type="text" id="cir_up" v-model="data.cir_up" />
												</b-form-group>
											</div>
										</div>
										<div class="row">
											<div class="col-12 col-sm-6">
												<b-form-group label="# of Private IPs" label-for="number_of_private_ips">
													<b-form-input type="number" id="number_of_private_ips" v-model="data.number_of_private_ips" />
												</b-form-group>
											</div>
											<div class="col-12 col-sm-6">
												<b-form-group label="# of Public IPs" label-for="number_of_public_ips">
													<b-form-input
														type="number"
														id="number_of_public_ips"
														v-model="data.number_of_public_ips"
														placeholder="Engineering Review Required"
													/>
												</b-form-group>
											</div>
										</div>
										<div class="row">
											<div class="col-12 col-sm-6">
												<h6>Additional Options</h6>
												<div class="col-12">
													<b-form-checkbox v-model="data.dhcp" value="Yes" unchecked-value="No" size="lg" switch>DHCP</b-form-checkbox>
												</div>
												<div class="col-12">
													<b-form-checkbox v-model="data.internet" value="Yes" unchecked-value="No" size="lg" switch>Internet</b-form-checkbox>
												</div>
												<div class="col-12">
													<b-form-checkbox v-model="data.vlan_tagging" value="Yes" unchecked-value="No" size="lg" switch
														>VLAN Tagging</b-form-checkbox
													>
												</div>
												<div class="col-12">
													<b-form-checkbox v-model="data.monitoring" value="Yes" unchecked-value="No" size="lg" switch>Monitoring</b-form-checkbox>
												</div>
												<div class="col-12">
													<b-form-checkbox v-model="data.nat_required" value="Yes" unchecked-value="No" size="lg" switch
														>NAT Required (At ASA)</b-form-checkbox
													>
												</div>
												<div class="col-12 mb-3">
													<b-form-checkbox v-model="data.terrestrial_redundancy" value="Yes" unchecked-value="No" size="lg" switch>
														Terrestrial Redundancy
													</b-form-checkbox>
												</div>
												<div class="col-12" v-if="data.terrestrial_redundancy === 'Yes'">
													<b-form-group label="Type of Redundancy">
														<b-form-radio-group v-model="data.type_of_terrestrial_redundancy" name="type_of_terrestrial_redundancy" size="lg">
															<b-form-radio value="VPN">VPN</b-form-radio>
															<b-form-radio value="Leased Line">Leased Line</b-form-radio>
														</b-form-radio-group>
													</b-form-group>
												</div>
											</div>
											<div class="col-12 col-sm-6 mt-6 mt-sm-0">
												<b-form-group label="Backhaul to Client Premises">
													<b-form-radio-group v-model="data.backhaul" name="backhaul" size="lg" stacked>
														<b-form-radio value="VPN">VPN</b-form-radio>
														<b-form-radio value="Leased Line">Leased Line</b-form-radio>
														<b-form-radio value="MPLS">MPLS</b-form-radio>
														<b-form-radio value="Not Required">Not Required</b-form-radio>
													</b-form-radio-group>
												</b-form-group>
											</div>
										</div>
										<div class="row mt-5 mb-10"></div>
									</div>

									<!-- RF -->
									<div class="p-7 my-5 section" ref="rf">
										<h1 class="mb-5 font-weight-bold header-blue" v-b-visible="rfVisible">
											RF Requirements
										</h1>
										<hr class="mb-10" />
										<div class="row">
											<div class="col-6">
												<b-form-group label="RF Band">
													<b-form-radio-group v-model="data.rf_band" name="rf_band" size="lg">
														<b-form-radio value="Ku Band">Ku</b-form-radio>
														<b-form-radio value="C Band">C</b-form-radio>
													</b-form-radio-group>
												</b-form-group>
											</div>
											<div class="col-6">
												<b-form-group label="Polarization">
													<b-form-radio-group v-model="data.polarization" name="polarization" size="lg">
														<b-form-radio value="Linear">Linear</b-form-radio>
														<b-form-radio value="Circular">Circular</b-form-radio>
													</b-form-radio-group>
												</b-form-group>
											</div>
										</div>
										<div class="row">
											<div class="col-12 col-sm-6">
												<b-form-group label="Antenna Make/Model" label-for="antenna_model">
													<b-form-input type="text" id="antenna_model" v-model="data.antenna_model" />
												</b-form-group>
											</div>
											<div class="col-12 col-sm-6">
												<b-form-group label="Antenna Reg Code" label-for="antenna_reg_code">
													<b-form-input type="text" id="antenna_reg_code" v-model="data.antenna_reg_code" />
												</b-form-group>
											</div>
											<div class="col-12 col-sm-6">
												<b-form-group label="Antenna Serial Number" label-for="antenna_serial_number">
													<b-form-input type="text" id="antenna_serial_number" v-model="data.antenna_serial_number" />
												</b-form-group>
											</div>
											<div class="col-12 col-sm-6">
												<b-form-group label="Antenna Polarization">
													<b-form-radio-group v-model="data.antenna_polarization" name="antenna_polarization" size="lg">
														<b-form-radio value="Cross-Pol">Cross Pol</b-form-radio>
														<b-form-radio value="Co-Pol">Co Pol</b-form-radio>
														<b-form-radio value="Cross/Co-Pol">Both</b-form-radio>
													</b-form-radio-group>
												</b-form-group>
											</div>
										</div>
										<div class="row">
											<div class="col-12 col-sm-6">
												<b-form-group label="Modem Type" label-for="modem_type">
													<b-form-input type="text" id="modem_type" v-model="data.modem_type" />
												</b-form-group>
											</div>
											<div class="col-12 col-sm-6">
												<b-form-group label="Modem Serial Number" label-for="modem_serial_number">
													<b-form-input type="text" id="modem_serial_number" v-model="data.modem_serial_number" />
												</b-form-group>
											</div>
										</div>
										<div class="row">
											<div class="col-12 col-sm-6">
												<b-form-group label="BUC Make/Model" label-for="buc_model">
													<b-form-input type="text" id="buc_model" v-model="data.buc_model" />
												</b-form-group>
											</div>
											<div class="col-12 col-sm-6 col-lg-3">
												<b-form-group label="BUC LO" label-for="buc_lo">
													<b-form-input type="text" id="buc_lo" v-model="data.buc_lo" />
												</b-form-group>
											</div>
											<div class="col-12 col-sm-6 col-lg-3 mb-5 mt-lg-8">
												<b-form-checkbox v-model="data.buc_ifldc" value="Yes" unchecked-value="No" size="lg" switch>BUC iflDC ON</b-form-checkbox>
												<b-form-checkbox v-model="data.buc_ifl10mhz" value="Yes" unchecked-value="No" size="lg" switch
													>BUC ifl10Mhz ON</b-form-checkbox
												>
											</div>
										</div>
										<div class="row">
											<div class="col-12 col-sm-6">
												<b-form-group label="LNB Make/Model" label-for="lnb_model">
													<b-form-input type="text" id="lnb_model" v-model="data.lnb_model" />
												</b-form-group>
											</div>
											<div class="col-12 col-sm-6 col-lg-3">
												<b-form-group label="LNB LO" label-for="lnb_lo">
													<b-form-input type="text" id="lnb_lo" v-model="data.lnb_lo" />
												</b-form-group>
											</div>
											<div class="col-12 col-sm-6 col-lg-3 mb-5 mt-lg-8">
												<b-form-checkbox v-model="data.lnb_ifldc" value="Yes" unchecked-value="No" size="lg" switch>LNB iflDC ON</b-form-checkbox>
												<b-form-checkbox v-model="data.lnb_ifl10mhz" value="Yes" unchecked-value="No" size="lg" switch
													>LNB ifl10Mhz ON</b-form-checkbox
												>
											</div>
										</div>
									</div>

									<!-- COMMENTS -->
									<div class="p-7 mt-5 mb-10 section" ref="comments">
										<h1 class="mb-5 font-weight-bold header-blue" v-b-visible="commentsVisible">
											Notifications and Comments
										</h1>
										<hr class="mb-10" />
										<div class="row">
											<div class="col-12 col-sm-6 d-flex">
												<div class="flex-grow-1">
													<b-form-group label="Ticket Notification Email" label-for="notification_contacts">
														<b-form-input type="text" id="notification_contacts" v-model="input.email" />
													</b-form-group>
												</div>
												<div class="ml-2 mt-10">
													<b-button
														variant="outline-primary"
														@click="input.email && addTo('ticket_notification_contacts', 'email', { email: input.email })"
													>
														Add Email
													</b-button>
												</div>
											</div>
											<div class="col-12 col-sm-6">
												<b-form-group label="Engineering Team CC" label-for="engteam">
													<b-form-select v-model="data.engteam" id="engteam">
														<b-form-select-option value="apac">APAC</b-form-select-option>
														<b-form-select-option value="emea">EMEA</b-form-select-option>
														<b-form-select-option value="us">US</b-form-select-option>
													</b-form-select>
												</b-form-group>
											</div>
											<div class="col-12 d-flex flex-wrap mb-4" v-if="data.ticket_notification_contacts">
												<div
													v-for="(email, i) in data.ticket_notification_contacts"
													:key="email.email"
													class="select-item my-1"
													@click="removeFrom('ticket_notification_contacts', i)"
												>
													{{ email.email }}
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-12">
												<b-form-group label="Comments">
													<b-form-textarea v-model="data.comments" rows="7" />
												</b-form-group>
											</div>
										</div>
										<div class="row">
											<div class="col-12">
												<b-form-group label="Add a Link" label-for="link">
													<b-form-input type="text" id="link" v-model="data.link" @change="checkLink(data.link)" />
												</b-form-group>
											</div>
										</div>
										<div class="row">
											<div class="col-12">
												<b-form-checkbox v-model="data.portal_client_access" value="Yes" unchecked-value="No" size="lg" switch>
													Portal Client Access
												</b-form-checkbox>
											</div>
											<div class="col-12">
												<b-form-checkbox v-model="data.nms_monitoring" value="Yes" unchecked-value="No" size="lg" switch>
													Customer Has Proactive Monitoring
												</b-form-checkbox>
											</div>
										</div>
									</div>

									<!--Actions -->
									<div v-if="submitted && $v.data.$anyError" class="error-div text-center mb-5">
										<div>ERROR: Could not submit form</div>
										<div v-if="$v.data.request_type.$error" @click="scrollTo('details')">Request Type is required</div>
										<div v-if="$v.data.billing_type.$error" @click="scrollTo('details')">Billing Type is required</div>
										<div v-if="$v.data.itc_quote_number.$error" @click="scrollTo('details')">Quote Number/MSA is required</div>
										<div v-if="$v.data.customer_name.$error" @click="scrollTo('details')">Customer Name is required</div>
										<div v-if="$v.data.site_name.$error" @click="scrollTo('details')">Site Name is required</div>
										<div v-if="$v.data.service_start_date.$error" @click="scrollTo('details')">Start Date is required</div>
									</div>
									<div class="d-flex justify-content-center border-top pt-10" ref="bottom">
										<b-button
											type="submit"
											variant="success"
											class="font-weight-bold text-uppercase px-20 py-4"
											:class="{ 'spinner spinner-white spinner-right': submitting }"
											:disabled="submitting"
										>
											Submit
										</b-button>
										<b-button
											class="ml-3"
											variant="outline-success"
											v-if="edit"
											:class="{ 'spinner spinner-success spinner-right': submitting }"
											:disabled="submitting"
											@click="submitAsNew"
										>
											SUBMIT AS NEW
										</b-button>
									</div>
									<!--end: Wizard Actions -->
								</b-form>
							</div>
							<!--end: Wizard-->
						</div>
					</ITCSpinner>
				</div>
				<!--end: Wizard Body -->
			</div>
			<!--end: Wizard-->
		</div>
	</div>
</template>

<script>
import swMixin from '@/core/services/mixins/serviceworker.mixin';
import { mapGetters } from 'vuex';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import generateSrfPdf from '@/view/content/lib/srfPDF';

export default {
	name: 'SRFForm',
	mixins: [swMixin, validationMixin],
	components: {
		ITCSpinner: () => import('@/view/content/loaders/itcSpinner.vue'),
		VueTypeaheadBootstrap: () => import('vue-typeahead-bootstrap'),
	},
	data() {
		return {
			loaded: false,
			// data1: {
			// 	request_type: 'New Service',
			// 	billing_type: 'No Cost Change',
			// 	customer_name: 'Company A - Testing',
			// 	site_name: 'CA_Server_Room_8',
			// 	country_of_operation: 'United States',
			// 	portal_client_access: 'Yes',
			// 	itc_quote_number: 'QRN-456415',
			// 	service_start_date: '2017-04-04',
			// 	service_end_date: '2018-04-04',
			// 	target_completion_date: '2050-04-01',

			// 	technical_poc_name: 'Josh Palacios',
			// 	technical_poc_phone: '(727) 643 - 1234',
			// 	technical_poc_cell: '(727) 643 - 1234',
			// 	technical_poc_email: 'josh.palacios@itcglobal.com',

			// 	site_poc_name: 'Josh Palacios',
			// 	site_poc_phone: '(727) 643 - 1234',
			// 	site_poc_cell: '(727) 643 - 1234',
			// 	site_poc_email: 'josh.palacios@itcglobal.com',

			// 	site_type: 'Terrestrial',
			// 	location_type: 'Fixed',
			// 	location_lat: '142.2456',
			// 	location_lon: '90.455646',
			// 	imo_number: '218465-54',
			// 	requested_satellites: [{ name: 'IS14' }, { name: 'T12 VNS' }],
			// 	vessel_imo_number: 'A12-4654',
			// 	vessel_4g_redundancy: 'Yes',
			// 	terrestrial_redundancy: 'Yes',
			// 	type_of_terrestrial_redundancy: 'Leased Line',

			// 	cir_down: 256,
			// 	cir_up: 256,
			// 	mir_down: 1024,
			// 	mir_up: 1024,

			// 	handshake_signaling: 'Yes',
			// 	serial_gps: 'Yes',
			// 	open_amip: 'Yes',
			// 	beam_switch: 'Yes',
			// 	nat_required: 'Yes',
			// 	link_use: 'Primary',

			// 	countries_with_voice: [
			// 		{ name: 'Japan', dids_required: 1 },
			// 		{ name: 'Germany', dids_required: 2 },
			// 	],
			// 	customer_telco_devices: 'Yes',
			// 	customer_telco_device_types: [{ type: 'Device Type A' }, { type: 'Device Type B' }],

			// 	number_of_private_ips: 3,
			// 	number_of_public_ips: 1,
			// 	dhcp: 'Yes',
			// 	internet: 'Yes',
			// 	vlan_tagging: 'No',
			// 	monitoring: 'Yes',

			// 	rf_band: 'Ku Band',
			// 	polarization: 'Circular',

			// 	antenna_model: 'Intellian xyz',
			// 	antenna_reg_code: 'Dummy Reg Code',
			// 	modem_type: 'Dummy Modem',
			// 	modem_serial_number: 'Dummy Serial Number',
			// 	antenna_serial_number: 'Dummy Serial',

			// 	buc_model: 'Dummy BUC ',
			// 	buc_lo: '12.8',
			// 	buc_ifldc: 'Yes',
			// 	buc_ifl10mhz: 'No',

			// 	lnb_model: 'Dummy LNB',
			// 	lnb_lo: 'Test LO',
			// 	lnb_ifldc: 'No',
			// 	lnb_ifl10mhz: 'Yes',

			// 	backhaul: 'MPLS',

			// 	ticket_notification_contacts: [{ email: 'josh.palacios@itcglobal.com' }, { email: 'blankblank@itcglobal.com' }],
			// 	nms_monitoring: 'Yes',
			// 	comments: 'We can make changes as needed.',
			// 	link: 'http://google.com',
			// 	billing: 0,
			// },
			data: {
				request_type: '',
				billing_type: '',
				itc_quote_number: '',
				customer_name: '',
				site_name: '',
				request_description: '',
				service_start_date: '',
				service_end_date: '',
				country_of_operation: '',
				vessel_imo_number: '',
				target_completion_date: '',

				technical_poc_name: '',
				technical_poc_phone: '',
				technical_poc_cell: '',
				technical_poc_email: '',
				site_poc_name: '',
				site_poc_phone: '',
				site_poc_cell: '',
				site_poc_email: '',

				location_type: '',
				beam_switch: '',
				handshake_signaling: '',
				serial_gps: '',
				open_amip: '',
				location_lat: '',
				location_lon: '',
				site_type: '',
				link_use: '',
				vessel_4g_redundancy: '',
				requested_satellites: [],

				countries_with_voice: [],
				customer_telco_devices: '',
				customer_telco_device_types: [],

				mir_down: null,
				mir_up: null,
				cir_down: null,
				cir_up: null,
				number_of_private_ips: null,
				number_of_public_ips: null,
				dhcp: '',
				internet: '',
				vlan_tagging: '',
				monitoring: '',
				nat_required: '',
				backhaul: '',
				terrestrial_redundancy: '',
				type_of_terrestrial_redundancy: '',

				rf_band: '',
				polarization: '',
				antenna_model: '',
				antenna_reg_code: '',
				antenna_serial_number: '',
				antenna_polarization: '',
				modem_type: '',
				modem_serial_number: '',
				buc_model: '',
				buc_lo: '',
				buc_ifldc: '',
				buc_ifl10mhz: '',
				lnb_model: '',
				lnb_lo: '',
				lnb_ifldc: '',
				lnb_ifl10mhz: '',

				ticket_notification_contacts: [],
				engteam: '',
				comments: '',
				link: '',
				portal_client_access: '',
				nms_monitoring: '',
				billing: '',
			},
			input: {},
			srf: null,
			satList: null,
			regions: null,
			regionsList: [],
			modems: [],
			visible: {
				details: false,
				remote: false,
				voice: false,
				ip: false,
				rf: false,
				comments: false,
			},
			active: 'details',
			submitted: false,
			submitting: false,
		};
	},
	validations: {
		data: {
			request_type: {
				required,
			},
			billing_type: {
				required,
			},
			itc_quote_number: {
				required,
			},
			customer_name: {
				required,
			},
			site_name: {
				required,
			},
			service_start_date: {
				required,
			},
		},
	},
	computed: {
		...mapGetters(['currentUser', 'viewAs']),
		edit() {
			return this.$route.name == 'editsrf';
		},
	},
	methods: {
		addSatellite(satellite) {
			if (satellite) {
				this.data.requested_satellites = this.data.requested_satellites || [];
				if (!this.data.requested_satellites.some(sat => sat.name === satellite)) {
					this.data.requested_satellites.push({ name: satellite });
					this.satList = this.satList.filter(sat => sat !== satellite);
					this.input.satellite = '';
				}
			}
		},
		removeSat(sat) {
			this.data.requested_satellites = this.data.requested_satellites.filter(s => s.name !== sat);
			this.satList.push(sat);
			this.satList.sort();
		},
		addRegionSats() {
			if (this.input.region) {
				this.regions.forEach(region => {
					if (region.name === this.input.region) {
						region.networks.forEach(n => {
							this.addSatellite(n.NetworkName);
						});
					}
				});
				this.regionsList.filter(region => region !== this.input.region);
				this.input.region = '';
			}
		},
		addTo(data, input, value) {
			if (this.data[data] == undefined) {
				this.$set(this.data, data, []);
			}
			if (value) {
				this.data[data].push(value);
			} else {
				this.data[data].push(this.input[input]);
			}
			if (Array.isArray(input)) {
				input.forEach(i => {
					this.input[i] = '';
				});
			} else {
				this.input[input] = '';
			}
		},
		removeFrom(data, index) {
			this.data[data].splice(index, 1);
		},
		setBillingFlag(type) {
			if (
				this.currentUser.role == 'user' ||
				this.currentUser.role == 'useradmin' ||
				['MRC Start', 'MRC Stop', 'Change with MRC Increase', 'Change with MRC Decrease'].includes(type)
			) {
				this.data.billing = 1;
			} else {
				this.data.billing = 0;
			}
		},
		checkLink(link) {
			if (link && link !== '' && link.substring(0, 3) !== 'htt') {
				this.data.link = 'https://' + link;
			}
		},
		validate(input) {
			if (this.submitted && this.$v.data[input].$error) {
				return false;
			}
			return null;
		},
		submit() {
			this.submitted = true;
			this.submitting = true;
			this.$v.data.$touch();
			if (!this.$v.$error) {
				if (this.edit) {
					let payload = {
						data: this.data,
						status: this.srf.status,
						assigned_to: this.srf.assigned_to,
					};
					this.$http.post(`srf/${this.$route.params.id}`, payload).then(resp => {
						generateSrfPdf(this.data);
						this.$router.push({ name: 'srflist' });
						this.submitting = false;
					});
				} else {
					this.postNew();
				}
			} else {
				this.submitting = false;
			}
		},
		postNew() {
			this.data.requestor = this.currentUser.username;
			this.$http.post('/srf/create', this.data).then(resp => {
				this.data.SRFID = resp.data.data.id;
				generateSrfPdf(this.data);
				if (this.currentUser.role == 'user' || this.currentUser.role == 'useradmin') {
					this.$bvModal
						.msgBoxOk('Please complete the Sales Order Form and submit it to your Account Manager', {
							title: 'SRF Submission Complete',
						})
						.then(() => {
							this.$router.push({ name: 'srflist' });
						});
				} else {
					this.$router.push({ name: 'srflist' });
				}
				this.submitting = false;
			});
		},
		submitAsNew() {
			this.submitted = true;
			this.submitting = true;
			this.$v.data.$touch();
			if (!this.$v.$error) {
				this.postNew();
			} else {
				this.submitting = false;
			}
		},
		//SIDEBAR NAV FUNCTIONS
		scrollTo(ref) {
			this.$refs[ref].scrollIntoView({ behavior: 'smooth' });
		},
		setVisible() {
			let set = false;
			for (let section in this.visible) {
				if (this.visible[section] && !set) {
					this.active = section;
					set = true;
				}
			}
		},
		detailsVisible(isVisible) {
			this.visible.details = isVisible;
			this.setVisible();
		},
		remoteVisible(isVisible) {
			this.visible.remote = isVisible;
			this.setVisible();
		},
		voiceVisible(isVisible) {
			this.visible.voice = isVisible;
			this.setVisible();
		},
		ipVisible(isVisible) {
			this.visible.ip = isVisible;
			this.setVisible();
		},
		rfVisible(isVisible) {
			this.visible.rf = isVisible;
			this.setVisible();
		},
		commentsVisible(isVisible) {
			this.visible.comments = isVisible;
			this.setVisible();
		},
	},
	created() {
		if (this.edit) {
			this.loaded = false;
			this.$http.get(`srf/${this.$route.params.id}`).then(resp => {
				let { data, ...srf } = resp.data.data;
				this.data = { ...this.data, ...data };
				this.srf = srf;
				this.loaded = true;
			});
		} else if (this.$route.params.id) {
			this.loaded = false;
			this.$http.get(`srf/${this.$route.params.id}`).then(resp => {
				this.data = { ...this.data, ...resp.data.data.data };
				this.loaded = true;
			});
		}
		let account_id = null;
		if(this.viewAs.AccountID) {
				account_id = this.viewAs.AccountID; 
			} else {
				account_id = this.currentUser.AccountID;
			};
		this.$http.post('srf/sat_list', account_id).then(resp => {
			this.satList = resp.data.data;
		});
		this.$http.get('srf/regionlinks').then(resp => {
			this.regions = resp.data.data;
			this.regionsList = this.regions.map(r => r.name);
		});
		this.$http.get('srf/modems').then(resp => {
			this.modems = resp.data.data;
			this.loaded = true;
		});
		this.$store.dispatch(SET_BREADCRUMB, [
			{ title: 'SRF', route: { name: 'srflist' } },
			{ title: this.$route.name == 'newsrf' ? 'New SRF' : 'Edit SRF' },
		]);
		// this.setSWListener('srf/sat_list', data => {
		// 	this.satList = data.data;
		// });
	},
	beforeCreate() {
		this.$http.get('srf/authcheck').then(resp => {
			if (!resp.data.data.srf_auth) {
				this.$router.push({ name: 'dash' });
			}
		});
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/pages/wizard/wizard-2.scss';

.header-blue {
	color: #002664;
}
.pointer {
	cursor: pointer;
}
::v-deep #srf .form-group label,
::v-deep #srf .form-group legend {
	font-size: 1.35rem;
	font-weight: 400;
}
::v-deep .required-field:after {
	content: '*';
	color: red;
	padding: 2px;
	font-size: 1.2em;
}
::v-deep #srf label,
::v-deep #srf legend {
	font-weight: 500;
}
::v-deep .typeahead-invalid input {
	border-color: #f64e60;
	padding-right: calc(1.5em + 1.3rem);
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23F64E60' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F64E60' stroke='none'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right calc(0.375em + 0.325rem) center;
	background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
}
.header-nav {
	position: sticky;
	background: white;
	top: 80px;
	width: 100%;
	padding: 10px;
	z-index: 10;
	border: 1px solid #00266428;
	border-radius: 10px;
}
.section {
	border: 1px solid #00266428;
	border-radius: 10px;
	background: white;
	scroll-margin-top: 11rem;
}
.active {
	transition: $transition-link;
	color: $primary;

	.wizard-icon {
		transition: $transition-link;
		i {
			color: $primary !important;
		}

		::v-deep .svg-icon {
			@include svg-icon-color($primary, true);
		}
	}

	// &:after {
	// 	left: 100%;
	// 	top: 50%;
	// 	transform: translateY(-50%);
	// 	content: ' ';
	// 	height: 0;
	// 	width: 0;
	// 	border: solid transparent;
	// 	position: absolute;
	// 	border-left-color: $gray-100;
	// 	border-width: 1rem;
	// }
}
.select-item {
	padding: 2px 5px;
	background: #428bffdc;
	color: white;
	margin-right: 3px;
	border-radius: 5px;
	cursor: pointer;
}
.select-item:hover {
	background: #f64e60;
}
.error-div div {
	color: #f64e60;
	cursor: pointer;
}
@media screen and (max-width: 769px) {
	.header-nav {
		top: 55px;
	}
	.section {
		scroll-margin-top: 10rem;
	}
	.wizard-body.loaded {
		background: #ecf0f6;
	}
}
</style>
